/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  background: #281E0E;
  font-family: "DM Sans", sans-serif;
  font-size: clamp(1rem, 0.25rem + 2.5vw, 1.25rem);
  line-height: 1.5;
  color: #e9e8e6;
}

a:link,
a:visited {
  color: #ffac00;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  transition: color 0.25s linear;
}

a:hover,
a:active {
  color: #E96300;
}

.message {
  width: 100%;
  max-width: 50ch;
  opacity: 0;
  animation: fade_in 0.25s linear forwards;
}

.message-header {
  margin-bottom: 3rem;
}
.message-header img {
  display: block;
  width: 30%;
  max-width: 12ch;
}

.message-content > *:first-child {
  font-size: 3rem;
  font-size: clamp(2.25rem, 0rem + 7.5vw, 3rem);
  line-height: 1.2;
  font-weight: 700;
}
.message-content > * + * {
  margin-top: 1rem;
}

.message-footer {
  margin-top: 3rem;
  font-size: 0.8125rem;
  color: #534b3e;
}
.message-footer a:link,
.message-footer a:visited {
  color: #534b3e;
}
.message-footer a:hover,
.message-footer a:active {
  color: #ffac00;
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}