@use "sass:math";
@import '../../node_modules/reset-css/sass/reset.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700');

@function stripUnits($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return math.div($number, ($number * 0 + 1))
	}
	@return $number;
}

@mixin responsiveFontSize($maxWidth, $minWidth, $maxFontSize, $minFontSize) {
	// strip units from pixel sizes and convert to unitless rems
	$minWidth: math.div(stripUnits($minWidth), 16);
	$maxWidth: math.div(stripUnits($maxWidth), 16);
	$maxFontSize: math.div(stripUnits($maxFontSize), 16);
	$minFontSize: math.div(stripUnits($minFontSize), 16);

	// calculate slope
	$slope: math.div(($maxFontSize - $minFontSize), ($maxWidth - $minWidth));

	// calculate yAxisIntersection
	$yAxisIntersection: ($minWidth * -1) * $slope + $minFontSize;

	// add proper units
	$minFontSize: $minFontSize * 1rem;
	$yAxisIntersection: $yAxisIntersection * 1rem;
	$slope: $slope * 100 * 1vw;
	$maxFontSize: $maxFontSize * 1rem;

	// export css
	font-size: clamp(#{$minFontSize}, #{$yAxisIntersection} + #{$slope}, #{$maxFontSize});
}

$color-brown-dark: #281E0E;
$color-brown: #534b3e;
$color-brown-light: #7e786e;
$color-brown-lighter: #e9e8e6;
$color-brown-lightest: #f4f3f2;
$color-gold: #ffac00;
$color-orange: #E96300;

$color-black: #000000;
$color-white: #ffffff;

$color-facebook: #1877f2;
$color-instagram: #c32aa3;
$color-twitter: #1da1f2;
$color-yelp: #d32323;

body {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5%;
	background: $color-brown-dark;
	font-family: 'DM Sans', sans-serif;
	// font-size: 1.25rem;
	@include responsiveFontSize(640px, 480px, 20px, 16px);
	line-height: 1.5;
	color: $color-brown-lighter;
}

a:link,
a:visited {
	color: $color-gold;
	text-decoration: underline;
	text-underline-offset: 0.2em;
	transition: color 0.25s linear;
}

a:hover,
a:active {
	color: $color-orange;
}

.message {
	width: 100%;
	max-width: 50ch;
	opacity: 0;
	animation: fade_in 0.25s linear forwards;
}

.message-header {
	margin-bottom: 3rem;
	img {
		display: block;
		width: 30%;
		max-width: 12ch;
	}
}

.message-content {
	> *:first-child {
		font-size: 3rem;
		@include responsiveFontSize(640px, 480px, 48px, 36px);
		line-height: 1.2;
		font-weight: 700;
	}
	> * + * {
		margin-top: 1rem;
	}
}

.message-footer {
	margin-top: 3rem;
	font-size: 0.8125rem;
	color: $color-brown;
	a:link,
	a:visited {
		color: $color-brown;
	}
	a:hover,
	a:active {
		color: $color-gold;
	}
}

@keyframes fade_in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
